<template>
  <router-view />
</template>
<script>
import axios from 'axios'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const router = useRouter()
    setInterval(() => {
      axios.post('/api/user/info')
    }, 60000)
    onMounted(()=>{
      var userAgentInfo = navigator.userAgent;
          console.log(navigator.userAgent);
          var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");
          var isPc = true;
          for (var v = 0; v < Agents.length; v++) {
              if (userAgentInfo.indexOf(Agents[v]) > 0) { isPc = false; break; }
          }
          if(!isPc){
            router.push('/usepc')
          }
    })
  },
}
</script>
<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
