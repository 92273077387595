import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'v-calendar/dist/style.css'
import * as defines from '@/defines'
const app = createApp(App)
app.config.globalProperties.$defines = defines

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.use(setupCalendar)
app.use(ElementPlus)
app.component('Calendar', Calendar)
app.component('DatePicker', DatePicker)
app.mount('#app')
