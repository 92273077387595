import axios from 'axios'
import toggleLoading from '@/plugins/LoadingSpinner'

/**
 * @instance axios 實體，方便進行 config 及攔截器的管理
 */
const request = axios.create({
  baseURL: '/api',
})

const handleError = (error) => {
  return {
    data: false,
    error: true,
    msg: error.toString(),
  }
}

request.interceptors.request.use(function (config) {
  toggleLoading(true)
  return config
}, handleError)

request.interceptors.response.use(function (response) {
  toggleLoading(false)
  return response.data
}, handleError)

export default request
