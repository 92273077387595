import { createApp } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

let instance = null
const toggleLoading = (bool) => {
  if (!instance) {
    const container = document.createElement('div')
    instance = createApp(LoadingSpinner).mount(container)
    document.body.appendChild(container)
  }

  instance.isLoading = bool
}

export const manualToggleLoading = (bool) => {
  if (!instance) {
    const container = document.createElement('div')
    instance = createApp(LoadingSpinner).mount(container)
    document.body.appendChild(container)
  }

  instance.manualLoading = bool
}

export default toggleLoading
