import axios from '@/plugins/axios'

const isNiceUrl = process.env.NODE_ENV == 'production'

export function builderUrl(route) {
  if (isNiceUrl) {
    return route
  } else {
    // return '?r=' + route // FIX
    return route
  }
}

export function callApi(method, route, payload = {}) {
  const url = builderUrl(route)
  if (method == 'post') {
    return axios.post(url, payload)
  }
  return axios.get(url)
}
