export const SAVE_ACTION_CREATE = 'create'
export const SAVE_ACTION_UPDATE = 'update'
export const SAVE_ACTION_DELETE = 'delete'
export const SAVE_ACTION_NONE = 'none'
export const SAVE_ACTION_CONFIRM = 'confirm'
export const SAVE_ACTION_SAVE = 'save'
export const SAVE_ACTION_COPY = 'copy'

export const ItemTypes = {
  ChoiceMultiple: 'ChoiceMultiple',
  ChoiceOne: 'ChoiceOne',
  ColumnInput: 'ColumnInput',
  DateInput: 'DateInput',
  Introduction: 'Introduction',
  ItemSort: 'ItemSort',
  NumberInput: 'NumberInput',
  NumberSlider: 'NumberSlider',
  Scale: 'Scale',
  Score: 'Score',
  TextItem: 'TextItem',
  TextLong: 'TextLong',
  UploadFile: 'UploadFile',
  DynamicForm: 'DynamicForm',
}

export const ItemTypesMap = {
  [ItemTypes.TextItem]: 'Input',
  [ItemTypes.ChoiceMultiple]: 'CheckBox',
  [ItemTypes.ChoiceOne]: 'Radio',
  [ItemTypes.ColumnInput]: 'Form',
  [ItemTypes.DateInput]: 'Date',
  [ItemTypes.Introduction]: 'CategoryTitle',
  [ItemTypes.ItemSort]: 'ItemSort',
  [ItemTypes.NumberInput]: 'NumberInput',
  [ItemTypes.NumberSlider]: 'RangeBar',
  [ItemTypes.Scale]: 'Nest',
  [ItemTypes.Score]: 'Star',
  [ItemTypes.TextLong]: 'Textarea',
  [ItemTypes.UploadFile]: 'File',
  [ItemTypes.DynamicForm]: 'DynamicForm',
}

export const Part_Is_Display_Yes = '1'
export const Part_Is_Display_No = '0'

export const Item_Require_False = '0'
export const Item_Require_True = '1'

export const User_Is_True = '1'
export const User_Is_False = '2'

export const Part_Item_Required_Yes = '1'
export const Part_Item_Required_No = '0'

export const Part_Item_Score_Type_Star = '1'
export const Part_Item_Score_Type_Heard = '2'
export const Part_Item_Score_Type_Tick = '3'
export const Part_Item_Score_Type_Awesome = '4'

export const Part_Item_ColumnInput_Cols_Type_Text = '1'
export const Part_Item_ColumnInput_Cols_Type_CheckBox = '2'
export const Part_Item_ColumnInput_Cols_Type_NumberText = '3'
export const Part_Item_ColumnInput_Cols_CheckBox_True = '1'
export const Part_Item_ColumnInput_Cols_CheckBox_False = '2'

export const Part_Item_Date_Date = '1'
export const Part_Item_Date_Date_And_Time = '2'
export const Part_Item_Date_Date_Interval = '3'
export const Part_Item_Date_Date_And_Time_Interval = '4'

export const Part_Item_ScaleItem_Cols_Type_Text = '1'
export const Part_Item_ScaleItem_Cols_Type_CheckBox = '2'
export const Part_Item_ScaleItem_Cols_Type_NumberText = '3'
export const Part_Item_ScaleItem_Cols_CheckBox_True = '1'
export const Part_Item_ScaleItem_Cols_CheckBox_False = '2'

export const Part_Item_From_Type_Text = '1'
export const Part_Item_From_Type_True_Or_False = '2'
export const Part_Item_Date_Date_Number = '3'
export const Part_Item_From_Check_True = '1'
export const Part_Item_From_Check_False = '2'

export const Part_Item_Dynamic_Form_Type_Text = '1'
export const Part_Item_Dynamic_Form_Type_True_Or_False = '2'
export const Part_Item_Dynamic_Form_Date_Number = '3'
export const Part_Item_Dynamic_Check_True = '1'
export const Part_Item_Dynamic_Check_False = '2'

export const Part_Item_Choice_Last = '-1'

export const Questionnaire_Status_None = '0' // 未發布
export const Questionnaire_Status_Published = '1' // 發布
export const Questionnaire_Status_Cancel = '2' // 下架
