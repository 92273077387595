import {callApi} from "@/api/http";

export function signOut(payload) {
  return callApi('post','sign/out', payload)
}
export function getUserInfo(payload) {
  return callApi('post','user/info', payload)
}
export function signIn(payload) {
  return callApi('post','sign/in', payload)
}
export function userContact(payload) {
  return callApi('post','user/contact', payload)
}
