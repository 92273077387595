<template>
  <div v-show="isLoading || manualLoading" class="overlay">
    <span :style="styles" class="spinner spinner--rotate-diamond">
      <div :style="diamondStyle" class="diamond"></div>
      <div :style="diamondStyle" class="diamond"></div>
      <div :style="diamondStyle" class="diamond"></div>
    </span>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
/*
 * 此組件為讀取中（Loading）
 */
export default {
  name: 'LoadingSpinner',
  props: {
    /*
     * size:讀取中的粒子大小
     */
    size: {
      type: String,
      default: '2.5rem',
    },
    /*
     * color:讀取中的粒子顏色
     */
    color: {
      type: String,
      default: '#5da3c7',
    },
  },
  setup() {
    const isLoading = ref(false)
    const manualLoading = ref(false)
    return { isLoading, manualLoading }
  },
  computed: {
    diamondStyle() {
      const size = parseInt(this.size)
      return {
        width: size / 4 + 'rem',
        height: size / 4 + 'rem',
        '--bg-color': this.color,
      }
    },
    styles() {
      const size = parseInt(this.size)
      return {
        width: this.size,
        height: size / 4 + 'rem',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$accent: #5da3c7;
$duration: 1500ms;
$timing: linear;
.spinner {
  position: relative;
  * {
    line-height: 0;
    box-sizing: border-box;
  }
  .diamond {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0.125rem;
    background: var(--bg-color);
    transform: translateX(-50%) rotate(45deg) scale(0);
    animation: diamonds $duration $timing infinite;
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        animation-delay: -($duration / 1.5) * $i;
      }
    }
  }
}
@keyframes diamonds {
  50% {
    left: 50%;
    transform: translateX(-50%) rotate(45deg) scale(1);
  }
  100% {
    left: 100%;
    transform: translateX(-50%) rotate(45deg) scale(0);
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
