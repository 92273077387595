import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { getUserInfo } from '@/api/Info'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Sign'),
    beforeEnter: async (to, from, next) => {
      // reject the navigation
      if (Object.keys(store.state.userInfo).length) {
        next('/questionnaire')
      } else {
        const res = await getUserInfo()
        if (!res.error) {
          next('/questionnaire')
        } else {
          next()
        }
      }
    },
  },
  {
    path: '/usepc',
    component: () => import('@/views/IsMobile.vue'),
  },
  {
    path: '/questionnaire/:id?/:part?',
    name: 'Questionnaire',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/Questionnaire'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/Contact'),
  },
  {
    path: '/pretest',
    name: 'PreTest',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/PreTest'),
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function () {
    return { top: 0 }
    // if (to.hash) {
    //   return {
    //     el: to.hash,
    //     behavior: 'smooth',
    //   }
    // } else {
    //   return { x: 0, y: 0 }
    // }
  },
})

export default router
